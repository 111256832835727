import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Text from "../components/text"

import devImage from "../images/service-dev.jpg"
import consultancyImage from "../images/service-consultancy.jpg"
import writingImage from "../images/service-writing.jpg"
import accessibilityImage from "../images/service-accessibility.jpg"

const ServicesPage = () => (
    <Layout heading="Services">
        <SEO title="Web development and consultancy services" />
        <Text>
            <p>There are a few different services that I can provide, depending on your needs. Some projects might even need a bit of everything.</p>
            <p>Why not <a href="mailto:info@thinktherefore.co.uk">get in touch</a> if there’s something I can help with?</p>
        </Text>
        <div className="serviceList">
            <article className="service">
                <div className="service_text">
                    <h2>Web development</h2>
                    <p>Are you looking to bring on some extra helping hands to boost your existing team? Do you have more projects than you can handle internally? Perhaps I can help?</p>
                    <p>My skills cover the 'full stack' and I have a strong background in building accessible websites using progressive enhancement and a good mix of server and front-end technologies.</p>
                    <p>I have extensive experience building websites in .Net but I’ve also worked with WordPress and React.</p>
                </div>
                <div className="service_image">
                    <img src={devImage} alt="" />
                </div>
            </article>
            <article className="service">
                <div className="service_text">
                    <h2>Consultancy</h2>
                    <p>If you aren’t quite sure on the best approach or requirements then I can help you make decisions for your projects.</p>
                    <ul>
                        <li>Do you need an expensive agency that has access to more resources, would a single developer be more efficient or are you better off hiring someone to work internally?</li>
                        <li>Are there future requirements that need to be considered now or are they far enough away that a new system might be in place by then?</li>
                    </ul>
                    <p>These are the sort of questions we can go through to give you the best idea on how to achieve your goals.</p>
                </div>
                <div className="service_image">
                    <img src={consultancyImage} alt="" />
                </div>
            </article>
            <article className="service">
                <div className="service_text">
                    <h2>Technical writing</h2>
                    <p>Documentation can often be overlooked in projects but it can be the best way of keeping various parties in sync and informed about all the relevant details.</p>
                    <p>I can help you by writing any of the following:</p>
                    <ul>
                        <li>Function Specifications – Details of exactly what your project is going to do but not full of the technical 'how' details.</li>
                        <li>User Guides – Taking the functional and technical nature of your project and presenting it in clear, easy to understand language.</li>
                        <li>Release Notes – Re-purposing your diligently created commit logs into bite-sized information that makes sense to users.</li>
                    </ul>
                </div>
                <div className="service_image">
                    <img src={writingImage} alt="" />
                </div>
            </article>
            <article className="service">
                <div className="service_text">
                    <h2>Accessibility audits</h2>
                    <p>Go beyond automated tests with an audit that not only tells you real user issues but that also gives you solutions to all the identified problems.</p>
                    <p>Accessibility is always best to consider at the start and during a project but an audit is still a good way to catch any issues that might have slipped through your usual processes.</p>
                </div>
                <div className="service_image">
                    <img src={accessibilityImage} alt="" />
                </div>
            </article>
        </div>
    </Layout>
)

export default ServicesPage
